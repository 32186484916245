import "./src/styles/all.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const shouldUpdateScroll = () => {
  if (window.location.pathname !== "/") {
    window.scrollTo(0, 0)
  }
  return false
}

const onInitialClientRender = () => {
  window.addEventListener("popstate", event => {
    if (window.location.pathname === "/") {
      document.querySelector("body").style.opacity = 0
      window.location.href = window.location.pathname
    }
  })
}

export { shouldUpdateScroll, onInitialClientRender }
