// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blistex-brand-story-js": () => import("./../../../src/pages/blistex-brand-story.js" /* webpackChunkName: "component---src-pages-blistex-brand-story-js" */),
  "component---src-pages-blistex-relief-cream-js": () => import("./../../../src/pages/blistex-relief-cream.js" /* webpackChunkName: "component---src-pages-blistex-relief-cream-js" */),
  "component---src-pages-cold-sore-cream-js": () => import("./../../../src/pages/cold-sore-cream.js" /* webpackChunkName: "component---src-pages-cold-sore-cream-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infusions-js": () => import("./../../../src/pages/infusions.js" /* webpackChunkName: "component---src-pages-infusions-js" */),
  "component---src-pages-intensive-moisturiser-cherry-js": () => import("./../../../src/pages/intensive-moisturiser-cherry.js" /* webpackChunkName: "component---src-pages-intensive-moisturiser-cherry-js" */),
  "component---src-pages-intensive-moisturiser-js": () => import("./../../../src/pages/intensive-moisturiser.js" /* webpackChunkName: "component---src-pages-intensive-moisturiser-js" */),
  "component---src-pages-lip-brilliance-js": () => import("./../../../src/pages/lip-brilliance.js" /* webpackChunkName: "component---src-pages-lip-brilliance-js" */),
  "component---src-pages-lip-infusions-hydration-js": () => import("./../../../src/pages/lip-infusions-hydration.js" /* webpackChunkName: "component---src-pages-lip-infusions-hydration-js" */),
  "component---src-pages-lip-infusions-nourish-js": () => import("./../../../src/pages/lip-infusions-nourish.js" /* webpackChunkName: "component---src-pages-lip-infusions-nourish-js" */),
  "component---src-pages-lip-infusions-restore-js": () => import("./../../../src/pages/lip-infusions-restore.js" /* webpackChunkName: "component---src-pages-lip-infusions-restore-js" */),
  "component---src-pages-lip-infusions-soothing-js": () => import("./../../../src/pages/lip-infusions-soothing.js" /* webpackChunkName: "component---src-pages-lip-infusions-soothing-js" */),
  "component---src-pages-medplus-js": () => import("./../../../src/pages/medplus.js" /* webpackChunkName: "component---src-pages-medplus-js" */),
  "component---src-pages-medplus-stick-js": () => import("./../../../src/pages/medplus-stick.js" /* webpackChunkName: "component---src-pages-medplus-stick-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-sun-protection-js": () => import("./../../../src/pages/sun-protection.js" /* webpackChunkName: "component---src-pages-sun-protection-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-ultra-lip-balm-js": () => import("./../../../src/pages/ultra-lip-balm.js" /* webpackChunkName: "component---src-pages-ultra-lip-balm-js" */),
  "component---src-pages-where-to-buy-js": () => import("./../../../src/pages/where-to-buy.js" /* webpackChunkName: "component---src-pages-where-to-buy-js" */),
  "component---src-pages-wind-burn-js": () => import("./../../../src/pages/wind-burn.js" /* webpackChunkName: "component---src-pages-wind-burn-js" */),
  "component---src-pages-winter-lips-js": () => import("./../../../src/pages/winter-lips.js" /* webpackChunkName: "component---src-pages-winter-lips-js" */)
}

